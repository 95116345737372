import { helperForms } from './models/Comments';

async function getPublicKey() {
   const DATA = new FormData();
   DATA.set('__FORMNAME', 'site-key');

   let response = await fetch('ajax-actions', {
      method: 'POST',
      body: DATA,
   });

   return await response.text();
}

global.onLoadCallback = async function() {
   grecaptcha.render('captchaContainer', {
      sitekey: await getPublicKey(),
      theme: 'dark',
      size: 'normal',
   });
};

$('.sent-new').on('submit', (e) => {
   e.preventDefault();

   if ($(e.target).hasClass('review-form')) {
      helperForms(
         $(e.target),
         {
            url: 'ajax-actions',
         },
         'form-reviews',
         'Комментарий отправлен на модерацию'
      );
   } else {
      helperForms($(e.target), {
         url: 'ajax-actions',
      });
   }

   grecaptcha.reset();
});
