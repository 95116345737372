export class Comments {
   constructor(config = {}) {
      if (config) {
         this._config = config;
      } else {
         this._config = {
            url: 'comment-send',
         };
      }
   }

   get configURL() {
      return this._config.url;
   }

   mailError(data, Form) {
      _.map(data, (item) => {
         console.error(item.mailer);
         setTimeout(() => {
            Form.find('.loading-block')
               .addClass('error')
               .html(
                  '<p>Письмо не может быть отправлено. Попробуйте позже...</p>'
               );

            setTimeout(() => {
               Form.removeClass('loading');
               Form.find('.loading-block').remove();
            }, 2000);
         }, 500);
      });
   }

   defaultError(data, Form) {
      _.map(data, (item) => {
         let text = '';

         switch (item) {
            case 'name':
               text += 'Поля имя пустое';
               break;

            case 'review':
               text += 'Поля отзыва пустое или содержит недопустимые символы';
               break;

            case 'message':
               text +=
                  'Поле с сообщением пустое или содержит недопустимые символы';
               break;

            case 'email':
               text += 'Поле e-mail заполнено неправильно';
               break;

            default:
               text += 'Поле пустое или заполнено неправильно';
         }

         if (item !== 'captcha') {
            this._error(Form, item, text);
         } else {
            this._captchaError(Form);
         }
      });
   }

   _error(Form, item, text) {
      setTimeout(() => {
         Form.find('.loading-block')
            .addClass('error')
            .html('<p>В форме найдены ошибки</p>');
         Form.find(`#${item}`).addClass('errored');

         Form.find(`#${item}`)
            .siblings('.error-block')
            .text(text);

         setTimeout(() => {
            Form.removeClass('loading');
            Form.find('.loading-block').remove();
         }, 2000);
      }, 500);
   }

   _captchaError(Form) {
      setTimeout(() => {
         Form.find('.loading-block')
            .addClass('error')
            .html('<p>Captcha не пройдена или пройдена неверно</p>');

         setTimeout(() => {
            Form.removeClass('loading');
            Form.find('.loading-block').remove();
         }, 2000);
      }, 500);
   }

   init(Form) {
      Form.find('.input-field .error-block').html('');
      Form.find('input').removeClass('errored');
      Form.append(
         `<div class="loading-block"><p>Загрузка <i class="fas fa-spinner fa-spin"></i></p></div>`
      );
      Form.addClass('loading');
   }

   success(Form, message) {
      setTimeout(() => {
         Form.find('.loading-block')
            .addClass('success')
            .html(`<p>${message}</p>`);

         setTimeout(() => {
            Form.removeClass('loading');
            Form.find('.loading-block').remove();
         }, 2000);
      }, 500);

      Form[0].reset();
   }
}

export async function helperForms(
   Form,
   anyConfigClass = {},
   formName = 'send-form',
   formMessage = 'Форма успешно отправлена на почту'
) {
   const comments = new Comments(anyConfigClass);
   comments.init(Form);
   const formData = new FormData(Form[0]);
   formData.set('__FORMNAME', formName);
   let response = await fetch(comments.configURL, {
      method: 'POST',
      body: formData,
   });
   let serverResponse = await response.json();
   console.log(serverResponse);

   if (!serverResponse.error) {
      comments.success(Form, formMessage);
   } else {
      switch (serverResponse.errorType) {
         case 2:
            comments.mailError(serverResponse.data, Form);
            break;
         default:
            comments.defaultError(serverResponse.data, Form);
      }
   }
}
